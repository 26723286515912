<!-- 首页轮播 -->

<template>
	<div class="home-carousel main-cnt">
		<div class="content">
			<common-table ref="roleTable" tableHeight="calc(100vh - 325px)" :apiName="HomeCarousel.getBannerLists"
				:filters="filters" :columns="tableColumns" @onDelete="onDelete" @statusChange="statusChange"
				@onPowerStationNum="onPowerStationNum">
				<template #operate v-if="authData.indexOf('n_xlW1D9nVrJZ6RaViZchqAdxSjINI') != -1">
					<el-button type="primary" round @click="onAddBtn">
						添加轮播
					</el-button>
				</template>
			</common-table>
		</div>

		<!-- 新增 -->
		<w-dialog ref="refAddDialog" class="add-dialog" title="新增轮播" width="45%" btnWidth="140px" top="10vh"
			@wConfirm="wConfirm">
			<el-form class="add-form" ref="addForm" :model="ruleForm" :rules="rules" labelPosition="top">
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="轮播类型" prop="b_type">
							<el-select v-model="ruleForm.b_type" clearable placeholder="请选择轮播类型" @change="typeChange">
								<el-option label="公有" value="1"></el-option>
								<el-option label="私有" value="2"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<!-- 轮播类型为私有才选择 -->
					<el-col :span="12" v-if="ruleForm.b_type == '2'">
						<el-form-item class="tree-wp" label="关联电站" prop="b_station_ids">
							<el-select v-model="ruleForm.b_station_ids" clearable multiple collapse-tags filterable
								placeholder="请选择关联电站">
								<el-option v-for="itm in powerStationOptions" :key="itm.s_id" :label="itm.s_name"
									:value="itm.s_id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="跳转链接" prop="b_redirect_url">
							<el-input v-model="ruleForm.b_redirect_url" placeholder="请输入跳转地址" clearable
								@input="redirectUrlChange"></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="12" v-if="ruleForm.b_redirect_url !== ''">
						<el-form-item label="跳转类型" prop="b_redirect_type">
							<el-select v-model="ruleForm.b_redirect_type" clearable placeholder="请选择跳转类型"
								@change="redirectTypeChange">
								<el-option label="内部页面" :value="1"></el-option>
								<el-option label="外部H5" :value="2"></el-option>
								<el-option label="外部小程序" :value="3"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<!-- 跳转类型为外部小程序填写 -->
					<el-col :span="12" v-if="ruleForm.b_redirect_type == '3'">
						<el-form-item label="APPID" prop="b_redirect_appid">
							<el-input v-model="ruleForm.b_redirect_appid" placeholder="请输入小程序appid" clearable>
							</el-input>
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item label="轮播图片" prop="bannerFile">
							<img-upload :limit="1" :fileList="bannerFile" @uploadFile="uploadBannerFile"
								@deleteFile="deleteBanner" suggestText="" :uploadTitle="'banner图'">
							</img-upload>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</w-dialog>

		<!-- 详情 -->
		<w-dialog ref="refDialog" class="power-dialog" title="电站" width="50%" btnWidth="140px" top="10vh"
			:hideFooter="true">
			<div class="power-station">
				<div class="flex flex-flow">
					<div v-for="(item, i) in powerStationDatas" :key="i" class="power-station-content">
						<div>{{ item.station.s_name }}</div>
					</div>
				</div>
			</div>
		</w-dialog>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		computed,
		watch,
		reactive,
	} from "vue";
	import { ElMessage, ElMessageBox } from "element-plus";
	import { HomeCarousel, } from "@/plugins/api.js";
	import { useStore } from "vuex";
	import imgUpload from "@/components/img-upload/img-upload.vue";

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	// 监听权限
	watch(() => menuTokens.value, (data) => {
		if (data.length) {
			authData.value = data;
		}
	}, {
		deep: true,
		immediate: true,
	})
	/** 表格对象 */
	const roleTable = ref(null);
	const refAddDialog = ref(null);  // 新增弹框对象
	const refDialog = ref(null);  // 详情对象
	const powerStationOptions = ref([]);  // 关联电站选项
	const addForm = ref(null);  // 表单对象
	const bannerFile = ref([]);  // 上传图
	/** 表单数据对象 */
	const ruleForm = ref({
		b_type: '',  // 轮播类型 1：公有，2：私有
		b_station_ids: [],  // 关联电站id
		b_redirect_type: '',  // 跳转类型 1：内部页面，2：外部H5，3：外部小程序
		b_redirect_appid: '',  // 跳转小程序appid
		b_redirect_url: '',  // 跳转链接
	});
	/** 表单规则对象 */
	const rules = reactive({
		b_type: [{ required: true, message: "请选择轮播类型", trigger: "blur", }],
		b_station_ids: [{ required: true, message: "请选择关联电站", trigger: "blur", }],
		b_redirect_type: [{ required: true, message: "请选择跳转类型", trigger: "blur", }],
		b_redirect_appid: [{ required: true, message: "请输入小程序appid", trigger: "blur", }],
		b_redirect_url: [{ required: true, message: "请输入跳转地址", trigger: "blur", }],
	});
	/** 筛选条件列表 */
	const filters = ref([
		{
			filterType: "select",
			name: "type",
			value: "",
			placeholder: "请选择类型",
			options: [
				{ id: 1, name: '公有' },
				{ id: 2, name: '私有' },
			],
			val: "id",
			lab: "name",
		},
		{
			filterType: "select",
			name: "status",
			value: "",
			placeholder: "请选择状态",
			options: [
				{ id: 1, name: '开启' },
				{ id: 2, name: '关闭' },
			],
			val: "id",
			lab: "name",
		},
		{
			name: "keywords",
			filterType: "search",
			value: "",
			placeholder: "请输入电站名称查询",
		},
	]);
	/** 表格配置数据 */
	const tableColumns = ref([
		{
			prop: "type_text",
			label: "类型",
			showTooltip: true,
		},
		{
			type: "image",
			prop: "file_url",
			label: "轮播图",
			showTooltip: true,
		},
		{
			type: "block",
			prop: "station_num",
			label: "电站",
			active: "onPowerStationNum",
			showTooltip: true,
			token: "n_L4noUueLhZyBSLUaMEgKIpNb4m",
		},
		{
			prop: "b_redirect_url",
			label: "跳转链接",
			showTooltip: true,
		},
		{
			type: "switch",
			prop: "b_status",
			label: "状态",
			showTooltip: true,
			token: "n_L4noUueLhZyBSLUaMEgKIpNb4m",
		},
		{
			prop: "creater_uname",
			label: "创建人",
			showTooltip: true,
		},
		{
			prop: "b_ctime",
			label: "创建时间",
			showTooltip: true,
		},
		{
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 110,
			bottons: [
				{
					name: "删除",
					action: "onDelete",
					token: "n_EP1P2IMsRAGMXdfFw85xzurGdL",
				},
			],
		},
	]);
	const powerStationDatas = ref([]);  // 关联电站
	/**
	 * 
	 * 新增轮播按钮
	 * 
	 * **/
	const onAddBtn = () => {
		ruleForm.value.b_type = '';
		ruleForm.value.b_station_ids = [];
		ruleForm.value.b_redirect_type = '';
		ruleForm.value.b_redirect_appid = '';
		ruleForm.value.b_redirect_url = '';
		bannerFile.value = [];
		getPowerStationOptions();
		store.dispatch("getQiniuData");
		refAddDialog.value.show();
	}
	/**
	 * 
	 * 获取关联电站选项
	 * 
	 * **/
	const getPowerStationOptions = () => {
		HomeCarousel.getStationData().then((res) => {
			if (res.Code === 200) {
				powerStationOptions.value = res.Data;
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 选择轮播类型
	 * 
	 * */
	const typeChange = () => {
		ruleForm.value.b_station_ids = [];
	}
	/**
	 * 
	 * 选择跳转类型
	 * 
	 * */
	const redirectTypeChange = () => {
		ruleForm.value.b_redirect_appid = '';
	}
    /**
     * 
     * 输入跳转链接
     * 
     **/
	const redirectUrlChange = () => {
		if (!ruleForm.value.b_redirect_url) {
			ruleForm.value.b_redirect_type = '';
			ruleForm.value.b_redirect_appid = '';
		}
	}
	/**
	 * 
	 * 上传图
	 * 
	 * */
	const uploadBannerFile = (data) => {
		bannerFile.value.push({
			file_url: data.standard_url,
			key: data.key,
			name: data.filename,
			suffix: data.suffix,
			size: data.fsize,
		});
	};
	/**
	 * 
	 * 文件删除
	 * 
	 * */
	const deleteBanner = () => {
		bannerFile.value = [];
	};
	/**
	 * 
	 * 新增确定按钮
	 * 
	 * */
	const wConfirm = () => {
		addForm.value.validate((valid) => {
			if (valid) {
				if (bannerFile.value.length == 0) {
					ElMessage.error('请上传轮播图片！');
					return false;
				}
				let params = {
					b_type: ruleForm.value.b_type,
					b_station_ids: ruleForm.value.b_station_ids,
					b_redirect_type: ruleForm.value.b_redirect_type,
					b_redirect_appid: ruleForm.value.b_redirect_appid,
					b_redirect_url: ruleForm.value.b_redirect_url,
					image_filename: bannerFile.value[0].name,
					image_key: bannerFile.value[0].key,
					image_ext: bannerFile.value[0].suffix,
					image_size: bannerFile.value[0].size,
				};
				refAddDialog.value.isLoading = true;
				// console.log("params", params);
				HomeCarousel.addCarouselChart(params).then((res) => {
					refAddDialog.value.isLoading = false;
					if (res.Code === 200) {
						refAddDialog.value.close();
						roleTable.value.tableLoad();
						ElMessage.success('新增成功！');
					} else {
						ElMessage.error(res.Message);
					}
				});
			}
		})
	}
	/**
	 * 
	 * 查看电站
	 * 
	 * */
	const onPowerStationNum = (row) => {
		if (row.b_type == 2) {
			powerStationDatas.value = row.station;
			// 私有才能查看
			refDialog.value.show();
		}
	}
	/**
	 * 
	 * 修改状态
	 * 
	 * */
	const statusChange = (row) => {
		HomeCarousel.editBannerStatus({ b_id: row.b_id }).then((res) => {
			if (res.Code === 200) {
				roleTable.value.tableLoad();
				ElMessage.success('状态修改成功!');
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 删除按钮
	 * 
	 * */
	const onDelete = (row) => {
		ElMessageBox.confirm(`确定删除该轮播吗？`, '删除确认', {
			confirmButtonText: '确认',
			cancelButtonText: '取消',
		}).then(() => {
			HomeCarousel.deleteBanner({ b_id: row.b_id }).then((res) => {
				if (res.Code === 200) {
					roleTable.value.tableLoad();
					ElMessage.success('删除成功!');
				} else {
					ElMessage.error(res.Message);
				}
			});
		}).catch(() => { })
	}

	onMounted(() => {
		roleTable.value.tableLoad();
	});
</script>

<style lang="scss">
	.home-carousel {
		font-family: "Source Han Sans CN";

		.el-row {
			border: none;
		}

		.content {
			padding: 20px;
		}

		.power-station {
			min-height: 500px;
			max-height: 500px;
			overflow: auto;
		}

		.power-station-content {
			margin: 5px;
			padding: 5px 10px;
			border-radius: 3px;
			border: 1px solid #3ebb75;
			color: #3ebb75;
			background-color: #ebf8f1;
		}
	}
</style>